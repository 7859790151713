




































































import Vue from 'vue';
import { constructModifyName } from '@/api/projectDetail';
import cloneDeep from 'lodash/cloneDeep';

const columns = [
  {
    title: '清单名称',
    dataIndex: 'name',
    key: 'name',
    width: '40%',
    ellipsis: true,
    scopedSlots: { customRender: 'name' },
    align: 'left',
  },
  {
    title: '替换新名称（仅修改填写项）',
    dataIndex: 'newName',
    key: 'newName',
    scopedSlots: { customRender: 'newName' },
    align: 'center',
  },
];

export default Vue.extend({
  name: 'editLeftSideName',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    projectTree: {
      type: Array,
    },
  },
  data() {
    return {
      columns,
      editProjectTree: [],
      isCanSave: 1, // 0:命名重复 1:所有内容未更改, 2:可保存
      loading: false,
    };
  },
  watch: {
    visible: function (val) {
      if (val) {
        this.editProjectTree = this.initData(
          JSON.parse(JSON.stringify(this.projectTree))
        );
      }
    },
  },
  computed: {
    tableScroll() {
      const num: number = window.innerHeight * 0.5 - 52;
      return { y: num };
    },
  },
  methods: {
    initData(tree) {
      return tree.map((item) => {
        if (item.type === '03') {
          this.$delete(item, 'children');
        }
        item.children = item.children?.length
          ? this.initData(item.children.map((n) => ({ ...n, parent: item })))
          : null;
        return item;
      });
    },
    noHandle() {
      this.$emit('update:visible', false);
    },
    saveList() {
      if (this.isCanSave === 0) {
        this.isCanSave = 1;
        return;
      }
      const projectTree = cloneDeep(this.editProjectTree[0]);
      const treeData = this.deleteAttr([projectTree]);
      if (this.isCanSave === 1) return this.noHandle();
      this.loading = true;
      constructModifyName(treeData[0]).then((res: any) => {
        console.log('res', res);
        if (res.status === 200 && res.result) {
          this.isCanSave = 1;
          this.$message.success('修改成功');
          this.loading = false;
          this.$emit('updateList');
        }
      });
    },
    // 判断是否重名
    checkName(value, record) {
      const result = this.nodeIsInTree(record, this.editProjectTree);
      const node = result.parent?.children
        .filter((item) => item.sequenceNbr !== record.sequenceNbr)
        .find((item) => (item.newName || item.name) === value);
      if (node) {
        this.isCanSave = 0;
        this.$message.error('命名不可重复~');
        record.newName = '';
      } else if (value.trim() !== record.name) {
        record.newName = result.newName = value.trim();
      }
    },
    nodeIsInTree(node, tree) {
      const result = tree.find((t) => t.sequenceNbr === node.sequenceNbr);
      if (result) {
        return result;
      }
      for (const item of tree) {
        const result = this.nodeIsInTree(node, item.children || []);
        if (result) {
          return result;
        }
      }
      return undefined;
    },
    deleteAttr(tree) {
      return tree.map((item) => {
        this.$delete(item, 'parent');
        if (item.newName) this.isCanSave = 2;
        item.children = item.children?.length
          ? this.deleteAttr(item.children)
          : [];
        return item;
      });
    },
  },
});
