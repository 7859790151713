var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout',{staticClass:"project-detail"},[_c('detail-header'),_c('a-layout',[_c('detail-left-side'),_c('div',{directives:[{name:"drag",rawName:"v-drag"}],staticClass:"resize",attrs:{"title":"收缩侧边栏"}},[_vm._v("⋮")]),_c('a-layout-content',{staticClass:"content scrollbar"},[_c('div',{staticClass:"tender-price",style:(_vm.tenderPriceStyle)},[_c(_vm.componentName,{tag:"component",staticClass:"detail-component"}),_c('combined-price',{directives:[{name:"show",rawName:"v-show",value:(
            (_vm.isUnitProject && _vm.isUnfold && _vm.isTenderUnfold) ||
            (!_vm.isUnitProject && _vm.isRetractUnfold)
          ),expression:"\n            (isUnitProject && isUnfold && isTenderUnfold) ||\n            (!isUnitProject && isRetractUnfold)\n          "}],ref:"price",on:{"Unfold":_vm.setUnfold}}),(
            (_vm.isUnitProject && (!_vm.isUnfold || !_vm.isTenderUnfold)) ||
            (!_vm.isUnitProject && !_vm.isRetractUnfold) ||
            _vm.isExportMinimality
          )?_c('retract-price',{on:{"Unfold":_vm.setUnfold}}):_vm._e()],1),(_vm.isUnitProject)?_c('tender',{staticClass:"tender",style:(_vm.tenderStyle)}):_vm._e(),_c('unordered-info',{attrs:{"visible":_vm.unorderedVisible},on:{"update:visible":function($event){_vm.unorderedVisible=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }