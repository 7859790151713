









































import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ProjectStatus } from '@/interface';
import DetailHeader from './header.vue';
import DetailLeftSide from './leftSide.vue';
import InvitationToTender from './invitationToTender/index.vue';
import Tender from './tender/index.vue';
import CombinedPrice from './combinedPrice/index.vue';
import EngineeringProject from './engineeringProject/index.vue';
import SingleProject from './singleProject/index.vue';
import RetractPrice from '@/views/projects/detail/combinedPrice/retractPrice.vue';
import UnorderedInfo from '@/views/projects/detail/combinedPrice/unorderedInfo.vue';

export default Vue.extend({
  components: {
    UnorderedInfo,
    RetractPrice,
    DetailHeader,
    DetailLeftSide,
    InvitationToTender,
    Tender,
    CombinedPrice,
    EngineeringProject,
    SingleProject,
  },
  data() {
    return {
      status: ProjectStatus.EMPTY,
      unorderedVisible: false, // 未订购提示弹框是否显示
    };
  },
  watch: {
    projectBidSequenceNbr: function (val, oldVal) {
      if (val && val !== oldVal) {
        this.getProjectDetail();
      }
    },
    projectSpaceId: function (val, oldVal) {
      if (val && val !== oldVal) {
        this.getProjectSpace();
      }
    },
  },
  computed: {
    ...mapGetters([
      'isUnitProject',
      'isSingleProject',
      'isUnfold',
      'isTenderUnfold',
      'projectBidSequenceNbr',
      'isRetractUnfold',
      'isExportMinimality',
    ]),
    tenderPriceStyle(): { height: string; minHeight: string } {
      let height;
      let minHeight;
      if (!this.isTenderUnfold) {
        height = '0px';
        minHeight = '0px';
      } else {
        height = this.isUnfold ? '50%' : '100%';
        minHeight = '500px';
      }
      return {
        minHeight,
        height: this.isUnitProject ? height : '100%',
      };
    },

    tenderStyle(): { height: string; minHeight: string } {
      let tenderHeight;
      let minHeight;
      if (!this.isUnfold) {
        tenderHeight = '0px';
        minHeight = '0';
      } else {
        tenderHeight = this.isTenderUnfold ? '50%' : '100%';
        minHeight = '500px';
      }
      return {
        minHeight,
        height: tenderHeight,
      };
    },
    componentName(): string {
      if (this.isUnitProject) return 'InvitationToTender';
      if (this.isSingleProject) return 'SingleProject';
      return 'EngineeringProject';
    },
  },
  created() {
    this.getProjectDetail();
    this.EXPORT_PROCESS_STATUS_INIT();
    this.projectOperationStatusInit(); // 操作状态初始
    this.getProjectSpace();
    this.$store.commit('SET_SELECTSTATUS', false);
  },
  mounted() {
    //this.dragControllerDiv();
    this.$store.commit(
      'SET_PROJECTSEQUENCENBR',
      this.$route.query?.sequenceNbr
    );
  },
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let oDiv = el; // 获取当前元素
        oDiv.onselectstart = () => false;
        oDiv.onmousedown = (e) => {
          let currentEl = document.getElementsByClassName(
            'ant-layout-sider'
          )[0] as HTMLTableElement;
          // 算出鼠标相对元素的位置
          let disX = e.clientX - oDiv.offsetLeft;
          document.onmousemove = (e) => {
            let left = 0;
            if (e.clientX - disX > 500) {
              left = 500;
            } else {
              left = e.clientX - disX > 0 ? e.clientX - disX : 0;
            }
            // oDiv.style.left = left + 'px';
            currentEl.style.maxWidth = left + 'px';
            currentEl.style.flex = '0 0 0' + left + 'px';
          };
          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
            oDiv.onselectstart = null;
          };
        };
      },
    },
  },
  methods: {
    ...mapMutations([
      'RESET_RELEVANT_INFO',
      'SET_PROJECT_SPACE_DETAIL',
      'EXPORT_PROCESS_STATUS_INIT',
    ]),
    ...mapActions([
      'getProjectDetail',
      'projectOperationStatusInit',
      'getProjectSpace',
    ]),
    setUnfold(e = 0) {
      if (e) {
        if (this.$refs.price) {
          (this.$refs.price as any).change(0);
        }
      }
    },
  },
  destroyed() {
    this.RESET_RELEVANT_INFO();
  },
});
