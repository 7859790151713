













































































import { checkFileType } from '@/utils';
import resultCheck from './result-check.vue';
import Vue from 'vue';
import AliOss from '@/utils/alioss';
import * as api from '@/api/project';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'import-form',
  props: {
    importVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filepath: '',
      file: null,
      paramsFileData: '',
      checkVisible: false,
      loadingVisible: false,
    };
  },
  computed: {
    ...mapGetters(['projectSequenceNbr']),
    visible: {
      get(): boolean {
        return this.importVisible;
      },
      set(visible: boolean) {
        this.$emit('update:importVisible', visible);
      },
    },
  },
  components: {
    resultCheck,
  },
  methods: {
    /**
     * @description: 上传文件
     * @param {*} params
     * @return {*}
     */
    async uploadFile(params: any) {
      const isType = checkFileType(params.file.name, 'zip', 'rar', 'xml');
      if (!isType) {
        this.$message.error('上传文件格式不正确!');
      }
      const fileType = params.file.name.substring(
        params.file.name.lastIndexOf('.') + 1
      );
      this.file = params.file;
      if (this.file) {
        this.paramsFileData = await new AliOss().upload(this.file);
        if (fileType === 'xml') {
          this.paramsFileData && this.getInfo();
        } else {
          this.paramsFileData && (this.checkVisible = true);
        }

        this.close();
      }
    },
    beforeUpload(file) {
      const isType = checkFileType(file.name, 'zip', 'rar', 'xml');
      if (!isType) {
        this.$message.error('上传文件格式不正确!');
      }
      return isType;
    },
    getInfo() {
      this.loadingVisible = true;
      const Api = 'coccMtdsResource_1_import_constructValidate';
      let postData = {
        filePath: this.paramsFileData,
        constructId: this.$route.query.sequenceNbr || this.projectSequenceNbr,
      };
      api[Api](postData)
        .then((data) => {
          if (data.status === 200 && data.code === 'OK') {
            this.loadingVisible = false;
            location.reload();
          } else {
            this.loadingVisible = false;
            this.close();
          }
        })
        .catch(() => {
          this.loadingVisible = false;
          this.close();
        });
    },
    /**
     * @description: 本地招标项目导入
     * @param {*}
     * @return {*}
     */
    handleChange(info) {
      if (info.file?.status === 'error') {
        this.$message.error(`${info.file.name} 导入失败`);
        return;
      }
      this.filepath = '';
      if (info.file?.status && info.file?.status !== 'uploading') {
        const { result } = info.fileList[info.fileList.length - 1]
          ?.response as any;
        if (result.code === 0) {
          this.filepath = result.data;
          this.$nextTick(() => {
            this.checkVisible = true;
          });
        } else {
          this.$message.error(result.msg);
        }
      }
    },
    close() {
      this.$emit('update:importVisible', false);
    },
  },
});
