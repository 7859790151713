





















































































































































import Vue from 'vue';
import * as api from '@/api/project';
import { mapGetters, mapMutations } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { checkFileType } from '@/utils/index';
import { RefreshTreeFrom } from '@/interface/product';
import { coccSpsServer_1_construct_status_all } from '@/api/projectDetail';
import AliOss from '@/utils/alioss';
import { coccMtdsResource_1_import_toConstruct } from '@/api/project';

const columns = [
  {
    title: '导入文件名称',
    dataIndex: 'name',
    key: 'name',
    width: '40%',
    ellipsis: true,
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '选择数据表',
    dataIndex: 'levelType',
    key: 'levelType',
    scopedSlots: { customRender: 'levelType' },
  },
  {
    title: '校验状态',
    dataIndex: 'code',
    key: 'code',
    scopedSlots: { customRender: 'code' },
  },
];
export default Vue.extend({
  data() {
    return {
      ERROR: false,
      loading: false,
      expandedRowKeys: [] as string[],
      originalData: [] as any[],
      filterData: [] as Record<string, any>[],
      data: [] as any[],
      columns,
      checkedList: [],
      lackSpnumber: 0,
      lackUnnumber: 0,
      options: [
        { label: `缺失单位工程(0项)`, value: 3 },
        { label: '缺失清单项(0项)', value: 6 },
      ],
      dataTypeList: [
        { label: '未选择', value: 0 },
        { label: '单项工程', value: 2 },
        { label: '单位工程', value: 3 },
      ],
    };
  },
  props: {
    showform: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['sequenceNbr', 'projectSequenceNbr']),
    visible: {
      get(): boolean {
        return this.showform;
      },
      set(visible: boolean) {
        this.$emit('update:showform', visible);
      },
    },
  },
  watch: {
    showform(value: boolean) {
      if (value) {
        this.initData();
        this.filterData = [];
        this.find();
      }
    },
  },
  methods: {
    initData() {
      this.checkedList = [];
      this.lackSpnumber = 0;
      this.lackUnnumber = 0;
      this.ERROR = false;
    },
    ...mapMutations(['SET_RefreshTree']),
    // 失去焦点暂存名称
    handleFileNameChange(value, record) {
      if (!value.trim()) {
        this.$message.error('名字不可为空');
        return;
      }
      const result = this.nodeIsInTree(record, this.data);
      const node = result.parent?.nextNodes
        .filter((item) => item.id !== record.id)
        .find(
          (item) => (item.newName || item.name) === value && value !== '未命名'
        );
      if (node) {
        this.$message.error('命名不可重复~');
      } else {
        record.newName = result.newName = value;
      }
    },
    updateEditStatus(record) {
      record.nameEdit = !record.nameEdit;
      this.$nextTick(() => {
        if (record.nameEdit) {
          (this.$refs[record.id] as HTMLInputElement).focus();
        }
      });
    },
    levelTypeChange(record) {
      const { levelType } = record;
      let parent = record.parent;
      while (parent) {
        if (levelType === 2) {
          parent.levelType = parent.parent ? 0 : 1;
        } else if (levelType === 3 && parent.levelType === 3) {
          parent.levelType = 0;
        }
        parent = parent.parent;
      }
      this.clearChildren(record.nextNodes, levelType);
    },
    getSingleName(record) {
      let parent = record.parent;
      while (parent) {
        if (parent.levelType === 2) {
          return parent.name;
        } else {
          parent = parent.parent;
        }
      }
      return record.parent.name;
    },
    clearChildren(children = [], levelType = 2) {
      (children || []).forEach((child: any) => {
        child.levelType =
          (child.levelType !== 5 && levelType === 3) ||
          (levelType === 2 && child.levelType === 2)
            ? 0
            : child.levelType;
        this.clearChildren(child.nextNodes, levelType);
      });
    },
    levelTypeFocus(record) {
      const levelTypes = record.parent.nextNodes
        .filter((node) => node !== record)
        .map((item) => item.levelType);
      if (levelTypes.includes(2) || record.level === 1) {
        record.levelTypes = this.dataTypeList.filter((x: any) => x.value !== 3);
      } else if (
        levelTypes.includes(3) ||
        record.nextNodes?.find((node) => node.levelType === 5)
      ) {
        record.levelTypes = this.dataTypeList.filter((x: any) => x.value !== 2);
      } else {
        record.levelTypes = this.dataTypeList;
      }
    },
    /**
     * @description: 设置错误标识、缺失项数量
     * @param {*} code
     * @return {*}
     */
    setCode(code: number) {
      switch (code) {
        case 1:
        case 7:
          this.ERROR = true;
          break;
        case 3:
          this.lackSpnumber++;
          break;
        case 6:
          this.lackUnnumber++;
          break;
      }
    },
    /**
     * @description: 转换导入的树，添加id、parent等属性
     * @param {*} tree
     * @return {*}
     */
    transformTree(tree: Array<Record<string, any>>) {
      return tree.map((item) => {
        this.setCode(item.code);
        const node: Record<string, any> = {
          ...item,
          id: Math.random().toString(36).slice(2, 10),
          isStrict: item.levelType - item.level === 1,
          nameEdit: false,
          levelTypes: [
            { label: '未选择', value: 0 },
            { label: '单项工程', value: 2 },
            { label: '单位工程', value: 3 },
          ],
        };
        node.nextNodes = node.nextNodes?.length
          ? this.transformTree(
              node.nextNodes.map((n) => ({ ...n, parent: node }))
            )
          : null;
        return node;
      });
    },
    /**
     * @description: 设置筛选项label
     * @param {*}
     * @return {*}
     */
    setOptions() {
      return this.options.map((opt, i) => ({
        label: `缺失${
          !i ? `单位工程(${this.lackSpnumber}` : `清单项(${this.lackUnnumber}`
        }项)`,
        value: opt.value,
      }));
    },
    find() {
      this.initData();
      let Api = 'coccMtdsResource_1_import_constructUnitValidate';
      let postdata: Record<string, string> = {
        unitId: this.sequenceNbr,
        filePath: this.path,
      };
      if (this.type === '01') {
        Api = 'coccMtdsResource_1_import_constructValidate';
        postdata = {
          filePath: this.path,
          constructId: this.$route.query.sequenceNbr || this.projectSequenceNbr,
        };
      }
      api[Api](postdata)
        .then((data) => {
          if (data.status === 200 && data.code === 'OK') {
            this.originalData = [cloneDeep(data.result)];
            this.data = this.filterData = this.transformTree([data.result]);
            this.options = this.setOptions();
            this.$nextTick(() => {
              this.expandedRowKeys = [this.filterData[0].id];
            });
          } else if (!data.result) {
            const h = this.$createElement;
            this.$message.error(
              h('span', { style: 'white-space: pre-wrap' }, data.message)
            );
            this.close();
          } else {
            this.close();
          }
        })
        .catch(() => {
          this.close();
        });
    },
    /**
     * @description: 上传文件
     * @param {*} params
     * @return {*}
     */
    async uploadFile(params: any, record) {
      const isType = checkFileType(params.file.name, 'xlsx', 'xls');
      if (!isType) {
        this.$message.error('上传文件格式不正确!');
      }
      if (params.file) {
        const unitFile = await new AliOss().upload(params.file);
        this.coccMtdsResource_1_import_toConstruct(
          { ...params, unitFile },
          record
        );
      }
    },
    // 单位工程导入
    async coccMtdsResource_1_import_toConstruct(params, record) {
      this.initData();
      let apiData = {
        importFileUrl: params.unitFile, // Excel文件oss地址
        unitName: record.name, // 导入工程项目文件名称
        unitNameXlsx: params.file.name, // 导入工程项目文件excel的名称
        constructId: this.$route.query.sequenceNbr || this.projectSequenceNbr,
        cloudStorageUrl: this.path, // 工程项目oss地址
        upParentFileName: await this.getSingleName(record),
        fileTreeNode: this.mapTree(this.data)[0],
        unitFileUrl: record.filePath, // 导入单位工程节点的filePath
      };
      coccMtdsResource_1_import_toConstruct(apiData).then((res) => {
        if (res.status === 200 && res.result.code !== 1) {
          this.originalData = [cloneDeep(res.result)];
          this.data = this.filterData = this.transformTree([res.result]);
          this.options = this.setOptions();
          this.$nextTick(() => {
            this.expandedRowKeys = [this.filterData[0].id];
          });
        } else {
          this.$message.error('文件解析失败');
        }
      });
    },
    findUnit(nodes) {
      for (const item of nodes) {
        if (item.levelType === 3) {
          return true;
        }
        const hasUnit = this.findUnit(item.nextNodes || []);
        if (hasUnit) {
          return true;
        }
      }
      return false;
    },
    findParent(node, levelType) {
      let { parent } = node;
      while (parent) {
        if (parent.levelType === levelType) {
          return true;
        }
        parent = parent.parent;
      }
      return false;
    },
    checkData(data) {
      for (const item of data) {
        if (
          (item.levelType === 2 && !this.findUnit(item.nextNodes || [])) ||
          (item.levelType === 3 && !this.findParent(item, 2)) ||
          (item.levelType === 5 && !this.findParent(item, 3))
        ) {
          return false;
        }
        const result = this.checkData(item.nextNodes || []);
        if (!result) {
          return false;
        }
      }
      return true;
    },
    mapTree(data) {
      if (!data) return null;
      return data.map((item) => {
        const { id, isStrict, nameEdit, levelTypes, parent, ...other } = item;
        return {
          ...other,
          nextNodes: this.mapTree(item.nextNodes || null),
        };
      });
    },
    reset() {
      this.checkedList = [];
      this.data = this.filterData = this.transformTree(
        cloneDeep(this.originalData)
      );
      this.$nextTick(() => {
        this.expandedRowKeys = [this.filterData[0].id];
      });
    },
    async save() {
      console.log(this.data);
      // if (this.lackUnnumber) {
      //   this.$message.error('导入失败：缺失清单项');
      //   return;
      // }
      if (this.ERROR) {
        this.$message.error('导入失败：单位工程内缺失必须项');
        return;
      }
      if (this.type === '01' && !this.checkData(this.data)) {
        this.$message.error('导入失败：单位工程必须包含于单项工程');
        return;
      }
      const sequenceNbr =
        this.$route.query.sequenceNbr || this.projectSequenceNbr;
      if (!sequenceNbr) return;
      const allStatusRes = await coccSpsServer_1_construct_status_all(
        sequenceNbr as string
      );
      if (allStatusRes.status === 200) {
        const { importFile } = allStatusRes.result;
        if (importFile === 1) {
          this.$message.error('导入失败：程序正在进行其他的操作暂时不允许导入');
          return;
        }
      }
      this.loading = true;
      let Api = 'coccMtdsResource_1_import_constructUnitSave';
      let postdata: Record<string, any> = {
        upId: this.sequenceNbr,
        filePath: this.path,
      };
      if (this.type === '01') {
        Api = 'coccMtdsResource_1_import_constructSave';
        postdata = {
          constructId: sequenceNbr,
          ossFilePath: this.path,
          fileTreeNode: this.mapTree(this.data)[0],
        };
      }
      api[Api](postdata)
        .then(() => {
          if (this.type === '01') {
            location.reload();
          } else {
            this.SET_RefreshTree(RefreshTreeFrom.COMBINED_PRICE);
          }
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChange(values) {
      this.expandedRowKeys = [];
      const { newTree, keys } = this.filterTree(values, this.data);
      this.filterData = newTree;
      this.$nextTick(() => {
        this.expandedRowKeys = keys;
      });
    },
    filterTree(checked: number[], data) {
      if (!checked.length)
        return {
          newTree: data,
          keys: [data[0].id],
        };
      let newTree: any[] = [];
      const keys: string[] = [];
      const genTree = (newTree, data) => {
        for (const item of data) {
          if (checked.includes(item.code)) {
            const rowKeys = this.insertToTree(item, newTree);
            keys.push(...(rowKeys || []));
          } else {
            genTree(newTree, item.nextNodes || []);
          }
        }
      };
      genTree(newTree, data);
      return {
        newTree,
        keys,
      };
    },
    nodeIsInTree(node, tree) {
      const result = tree.find((t) => t.id === node.id);
      if (result) {
        return result;
      }
      for (const item of tree) {
        const result = this.nodeIsInTree(node, item.nextNodes || []);
        if (result) {
          return result;
        }
      }
      return undefined;
    },
    insertToTree(item: any, newTree: any) {
      const { nextNodes, ...other } = item;
      const keys: string[] = [item.id];
      let relation = other;
      let parentNode = item.parent;
      while (parentNode) {
        const result = this.nodeIsInTree(parentNode, newTree);
        if (!result) {
          keys.push(parentNode.id);
          relation = { ...parentNode, nextNodes: [relation] };
        } else {
          result.nextNodes = [...(result.nextNodes || []), relation];
          return keys;
        }
        parentNode = parentNode.parent;
      }
      newTree.push(relation);
      return keys;
    },
    close() {
      this.data = [];
      this.$emit('update:showform', false);
    },
  },
});
