








































































































































































































































































































import Vue from 'vue';
import groupPrice from '@/views/projects/popup/group-price.vue';
import * as api from '@/api/project';
import {
  coccMtdsResource_1_cocc_builder_constructZj,
  coccSpsServer_1_construct_status_merge,
  againMergeStatusByConstructId,
  deleteMergeStatus,
  deleteAgainMergeStatus,
  mergeInitCheck,
  coccSpsServer_1_coccBuilder_stopToSequenceNbr,
} from '@/api/projectDetail';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  GetZjCountType,
  RefreshTreeFrom,
  CheckErrorsType,
} from '@/interface/product';
import redDocument from './redDocument.vue';
import {
  coccMtdsResource_1_construct_mergeV1BySequenceNbr,
  generateBidDetail,
} from '@/api/project';
import OverPriceTips from '@/views/projects/detail/combinedPrice/overPriceTips.vue';
interface list {
  id: string | undefined;
  name: string | unknown;
  type?: string;
  unitId?: string;
  constructId?: string;
  ifChildrenMissing?: boolean;
  sequenceNbr?: string;
  bidSequenceNbr?: string;
  children?: string[];
}
interface TaskList {
  unitId: string | undefined;
  constructId?: string;
  type?: string;
  status?: string;
  code?: number;
}

export default Vue.extend({
  name: 'combinedPrice',
  components: {
    OverPriceTips,
    groupPrice,
    redDocument,
    combinedPriceAnalysis: () => import('../combinedPriceAnalysis.vue'),
    initCheck: () => import('./initCheck.vue'),
  },
  computed: {
    ...mapGetters([
      'refreshTreeFrom',
      'isUnitProject',
      'isSetup',
      'isPriceAdjustmentOrValuation',
      'isPriceAdjustment',
      'projectBidSequenceNbr',
      'isThereReplace',
      'projectSequenceNbr',
      'combinedPriceStatus',
      'listGenerating',
      'isRetractUnfold',
      'isStartCombined',
    ]),
    TaskList(): TaskList {
      return Vue.prototype.$websocket.getters.onTaskList;
    },
    isBlankProject(): boolean {
      return this.$store.getters.isBlankProject;
    },
    // progress(): number {
    //   return Math.ceil((this.successlist.length / this.list.length) * 100);
    // },
    priceAdjustmentList(): any {
      return Vue.prototype.$websocket.getters.onTaskList;
    },
  },
  watch: {
    // progress() {
    //   if (
    //     this.progress >= 100 &&
    //     !this.showtoast &&
    //     !this.isPriceAdjustmentOrValuation
    //   ) {
    //     this.$message.success('组价完成');
    //     this.endPrice(4);
    //   }
    // },
    TaskList: {
      handler() {
        if (this.TaskList && !this.isPriceAdjustmentOrValuation) {
          const wsData = this.TaskList as any;
          if (
            !this.lastBidSequenceNbr ||
            ['null'].includes(this.lastBidSequenceNbr)
          ) {
            // 最新的投标id;
            this.lastBidSequenceNbr = wsData.newConstructId as string;
          }
          if (wsData.allUnit.length) {
            // 所有的数据，
            this.list = [...wsData.allUnit, ...this.excelPdf];
          }
          const finish = [...wsData.succeed, ...wsData.error];
          this.successlist = this.list.filter((i) => {
            return finish.includes(i.id);
          });
          this.peandinglist = this.list.filter((i) => {
            return !finish.includes(i.id);
          });

          if ([1].includes(wsData.excelPdf)) {
            this.peandinglist = [...this.peandinglist];
          }
          // 所有工程项目完毕，开始生成pdfexcel
          if (wsData.status === 200) {
            switch (wsData.excelPdf) {
              case 0:
                // 失败
                this.$message.error('生成文件失败');
                this.endPrice(5);
                break;
              case 3:
                //已完成
                this.peandinglist = [];
                this.successlist = [...this.successlist, ...this.excelPdf];
                // 后端，有可能丢数据，所以这里也可以写
                this.endPrice(6);
                break;
            }
          }
          // localStorage.setItem(
          //   this.sequenceNbr,
          //   JSON.stringify({
          //     list: this.list,
          //     peandinglist: this.peandinglist,
          //     successlist: this.successlist,
          //   })
          // );
        }
      },
    },
    priceAdjustmentList: {
      handler() {
        if (this.priceAdjustmentList && this.isPriceAdjustmentOrValuation) {
          this.loading = true;
          const wsData = this.priceAdjustmentList as any;
          this.priceAdjustmentPress = wsData.status;
          if (wsData.allUnit.length) {
            // 所有的数据，
            this.list = [...wsData.allUnit, ...this.excelPdf];
          }
          const finish = wsData.succeed;
          this.successlist = this.list.filter((i) => {
            return finish.includes(i.id);
          });
          this.peandinglist = this.list.filter((i) => {
            return !finish.includes(i.id);
          });
          if ([1].includes(wsData.excelPdf)) {
            this.peandinglist = [...this.peandinglist];
          }
          // 所有工程项目完毕
          if (wsData.status === 100) {
            switch (wsData.excelPdf) {
              case 0:
                // 失败
                this.$message.error('生成文件失败');
                this.endPrice(5);
                break;
              case 3:
                //已完成
                this.peandinglist = [];
                this.successlist = [...this.successlist, ...this.excelPdf];
                // 后端，有可能丢数据，所以这里也可以写
                this.endPrice(6);
                break;
            }
          }
          // localStorage.setItem(
          //   this.sequenceNbr,
          //   JSON.stringify({
          //     list: this.list,
          //     peandinglist: this.peandinglist,
          //     successlist: this.successlist,
          //   })
          // );
        }
      },
    },
    projectBidSequenceNbr: {
      handler(val, oldval) {
        if (val && val !== oldval) {
          this.getZjCount(this.projectBidSequenceNbr);
        }
      },
      deep: true,
      immediate: true,
    },
    isPriceAdjustmentOrValuation: {
      handler(val) {
        // 首次进入，调价，计价之后
        if (!val) {
          this.getZjCount(this.projectBidSequenceNbr);
        } else {
          this.loading = true;
          this.start = true;
          this.progress = 0;
          this.$store.commit('SET_ISUNFOLD', true);
          this.$store.commit('SET_ISTENDERUNFOLD', true);
          this.$store.commit('SET_ISRETRACTUNFOLD', true);
          this.currentTime = Date.now();
          this.successlist = [];
          this.peandinglist = [
            {
              id: '1',
              name: '重新计价中',
            },
          ];
          if (this.start) {
            this.setIntervalInfo();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    loading: function (val, oldVal) {
      if (val !== oldVal) {
        this.setScrollbarHeight();
      }
    },
    isUnitProject: function (val, oldVal) {
      if (val !== oldVal) {
        this.setScrollbarHeight();
      }
    },
    isRetractUnfold: function (val, oldVal) {
      if (val !== oldVal) {
        this.setScrollbarHeight();
      }
    },
    isStartCombined: function (val) {
      if (val) this.starprice();
    },
  },
  data() {
    const anaylesisInfo: GetZjCountType = {
      jspp: 0,
      jzpp: 0,
      neverZj: 0,
      total: 0,
    };
    return {
      confirvisible: false,
      anaylesisInfo,
      bidSequenceNbr: '', //投标id
      lastBidSequenceNbr: '', //最新的投标id
      sequenceNbr: '', //招标id
      showtoast: false,
      readyPrice: false,
      groupshow: false,
      width: 360,
      scrollbarHeight: 0, // 进度滚动区域高度
      redDocumentHeight: 0, // 红头文件区域高度
      loading: false,
      excelPdf: [
        { id: '000000001', name: '生成投标文件Execl' },
        { id: '000000002', name: '生成投标文件PDF' },
      ],
      list: [] as list[],
      successlist: [] as list[],
      peandinglist: [
        {
          id: '1',
          name: '组价初始化中',
        },
      ] as list[], //正在进行中的
      priceAdjustmentPress: 0,
      successVisible: false, // 组价成功弹框是否显示
      timer: 0,
      currentTime: 0,
      start: false, // 是否停止轮询
      combinedPriceTimer: 0, // 组价时间定时函数
      combinedPriceUsedTime: 0, // 组价用时时间毫秒
      mergeInitErrors: [] as CheckErrorsType[], // 组价初始错误信息
      checkErrorVisible: false, // 初始化错误弹窗
      scrollDisabled: false, // 是否禁止滚动加载
      checkLoading: false, // 检测loading
      checkPage: 1,
      isStopCombined: false, // 是否点击停止一键组价
      overPriceVisible: false, // 是否弹框超出目标投标价
      overPriceInfo: null, // 超出目标投标价信息
      progress: 0, // 进度展示
      mergeAdjust: null, // 组价状态 0 组价 1 调价
      stopVisible: false, // 停止组价弹框提示
    };
  },
  created() {
    this.$store.commit('SET_COMBINEDPRICESTATUS', false);
    this.sequenceNbr =
      (this.$route.query.sequenceNbr as string) || this.projectSequenceNbr;
    if (!this.isPriceAdjustmentOrValuation) {
      this.isMerge();
    } else {
      this.againMergeStatusByConstructId();
    }
  },
  mounted() {
    this.setScrollbarHeight();
  },
  methods: {
    ...mapActions(['getProjectDetail', 'getGenerateListStatus']),
    ...mapMutations([
      'SET_RefreshTree',
      'SET_PRICE_ADJUSTMENT_VALUATION',
      'SET_IS_THERE_REPLACE',
    ]),
    setScrollbarHeight() {
      this.$nextTick(() => {
        const totalHeight = (this.$refs.combinedPrice as HTMLDivElement)
          .offsetHeight;
        const loadingHeight = (this.$refs.calculateLoading as HTMLDivElement)
          .offsetHeight;
        this.scrollbarHeight = totalHeight - loadingHeight - 137 - 42 - 100;
        this.redDocumentHeight = totalHeight - loadingHeight - 137 - 42
      });
    },
    isMerge() {
      coccSpsServer_1_construct_status_merge(this.sequenceNbr).then((res) => {
        console.log('🚀 是否正在组价', res.result);
        if (res.result) {
          this.start = true;
          this.getunitProject();
        } else {
          this.loading = false;
          this.getunitProject(1);
        }
      });
    },
    getunitProject(status = 0) {
      this.list = [];
      api
        .coccSpsServer_1_construct_status_merge_data(this.sequenceNbr)
        .then((res) => {
          console.log('组价过程：', res);
          if (res.status === 200 && res.result) {
            this.readyPrice = true;
            this.showtoast = false;
            this.loading = true;
            this.$store.commit('SET_COMBINEDPRICESTATUS', true);
            const mergeInfo = res.result;
            this.progress = mergeInfo.progress;
            this.mergeAdjust = mergeInfo.mergeAdjust;
            const allList = mergeInfo.allUnit;
            // const finish = mergeInfo.finishUnitMergeIds;
            const nowStatus = mergeInfo.nowUnitMergeIds;
            // this.successlist = allList.filter((i) => {
            //   return finish.includes(i.sequenceNbr);
            // });
            this.successlist = [
              {
                id: '1',
                name: '组价初始化已完成',
              },
            ];
            this.peandinglist = allList
              ? allList.filter((i) => {
                  return nowStatus?.includes(i.sequenceNbr);
                })
              : [];
            if (mergeInfo.mergeAdjust === 1) {
              const combinedList = allList
                ? allList.filter((i) => {
                    return nowStatus?.includes(i.sequenceNbr);
                  })
                : [];
              this.successlist = [...this.successlist, ...combinedList];
              console.log('successList', this.successlist);
              this.peandinglist = [
                {
                  id: '2',
                  name: '智能调价中',
                },
              ];
            }
            if (mergeInfo.scope) {
              this.overPriceInfo = JSON.parse(mergeInfo.scope);
              this.overPriceVisible = true;
              // this.endPrice(5);
            }
            if (mergeInfo.finishTime) {
              this.successlist = [
                {
                  id: '2',
                  name: '智能调价已完成',
                },
              ];
              this.endPrice();
              this.getGenerateListStatus();
              // 如果status === 1 代表初始进页面查询组价过程，不可清空修改项状态
              if (!status) {
                this.SET_IS_THERE_REPLACE(false);
              }
              this.deleteMergeStatus();
              this.timer && cancelAnimationFrame(this.timer);
              this.clearCombinedPriceTimer();
            } else {
              if (this.start) {
                this.setIntervalInfo();
                !this.combinedPriceTimer &&
                  this.combinedPriceTimeHandler(mergeInfo);
              }
            }
          } else {
            this.exceptionEndHandler();
          }
          console.log('res', res);
          // this.startWebSocket();
        });
    },
    /**
     * 异常结束处理
     */
    exceptionEndHandler() {
      this.successlist = [];
      this.list = [];
      this.peandinglist = [];
      this.loading = false;
      this.readyPrice = false;
      this.$store.commit('SET_COMBINEDPRICESTATUS', false);
      if (this.isPriceAdjustmentOrValuation) {
        this.SET_PRICE_ADJUSTMENT_VALUATION(false);
      }
      this.timer && cancelAnimationFrame(this.timer);
      this.clearCombinedPriceTimer();
    },
    deleteMergeStatus() {
      deleteMergeStatus(this.sequenceNbr).then((res) => {
        console.log('res', res);
      });
    },
    againMergeStatusByConstructId() {
      againMergeStatusByConstructId(this.sequenceNbr).then((res: any) => {
        if (res.status === 200 && res.result) {
          const mergeInfo = res.result;
          // this.peandinglist = mergeInfo.unitList;
          // this.successlist = [
          //   {
          //     id: '1',
          //     name: '组价初始化已完成',
          //   },
          // ];
          console.log(this.peandinglist);
          if (mergeInfo.finishTime) {
            this.SET_IS_THERE_REPLACE(false);
            this.getGenerateListStatus();
            this.endPrice(8);
            this.deleteAgainMergeStatus();
            this.start = false;
            this.timer && cancelAnimationFrame(this.timer);
          }
        } else {
          this.exceptionEndHandler();
        }
      });
    },
    deleteAgainMergeStatus() {
      deleteAgainMergeStatus(this.projectBidSequenceNbr).then((res) => {
        console.log('res', res);
      });
    },
    /**
     * @description: 关闭组价
     * @param {*}
     * @return {*}
     */
    endPrice(type = 1): void {
      // Vue.prototype.$websocket.commit('CLEAR_TASK');
      // Vue.prototype.$websocket.commit('WEBSOCKET_CLEAR');
      // setTimeout(() => {
      if (type === 8) {
        this.successlist = [];
        this.list = [];
        this.peandinglist = [];
      }
      // this.successlist = [];
      this.loading = false;
      this.isStopCombined = false;
      // this.list = [];
      // this.peandinglist = [];
      this.readyPrice = false;
      this.showtoast = true;
      //if (type) {
      this.$store.commit('SET_COMBINEDPRICESTATUS', false);
      console.log('组价完成准备关闭', type, this.lastBidSequenceNbr);
      if (this.isPriceAdjustmentOrValuation) {
        this.SET_PRICE_ADJUSTMENT_VALUATION(false);
      } else {
        this.SET_RefreshTree(RefreshTreeFrom.COMBINED_PRICE);
      }
      this.getProjectDetail();
      // if (!this.projectBidSequenceNbr) {
      //   console.log('if判断条件');
      //   this.successVisible = true;
      // } else {
      //   console.log('else条件');
      //   // this.getZjCount(this.lastBidSequenceNbr);
      if (type != 5) {
        this.$store.commit('SET_COMBINED_SUCCESS_VISIBLE', true);
      }
      this.start = false;
      this.timer && cancelAnimationFrame(this.timer);
      this.clearCombinedPriceTimer();
      //}
      // }
      // }
      // }, 1500);
    },
    /**
     * 组价时间轮询
     */
    async combinedPriceTimeHandler(mergeInfo) {
      if (!this.start) {
        this.clearCombinedPriceTimer();
        return false;
      }

      const startTime = mergeInfo.startTime;
      const nowTime = mergeInfo.nowTime;
      if (!startTime || !nowTime) {
        this.clearCombinedPriceTimer();
        return false;
      }
      this.combinedPriceUsedTime = Number(nowTime - startTime);
      if (this.combinedPriceTimer) {
        return false;
      }
      this.combinedPriceTimer = setInterval(() => {
        this.combinedPriceUsedTime += 1000;
      }, 1000);
    },
    clearCombinedPriceTimer() {
      if (this.combinedPriceTimer) {
        clearInterval(this.combinedPriceTimer);
        this.combinedPriceTimer = 0;
      }
    },
    /**
     * @description: 根据投标id,获取清单总清单等
     * @param {*}
     * @return {*}
     */
    getZjCount(id: string) {
      if (id) {
        coccMtdsResource_1_cocc_builder_constructZj(id)
          .then((res) => {
            if (res.status === 200) {
              this.anaylesisInfo = res.result;
            }
          })
          .finally(() => {
            this.lastBidSequenceNbr = '';
          });
      }
    },
    Unfold() {
      this.$store.commit('SET_ISRETRACTUNFOLD', !this.isRetractUnfold);
      if (this.$store.getters.isUnitProject) {
        this.$store.commit('SET_ISUNFOLD', false);
        this.$store.commit('SET_ISTENDERUNFOLD', true);
      } else {
        // this.change();
        this.$emit('Unfold');
      }
    },
    generateBidDetail() {
      generateBidDetail(this.projectBidSequenceNbr).then((res) => {
        console.log('res');
        this.getGenerateListStatus();
      });
    },
    initCheckErrorLoad() {
      return this.mergeCheck();
    },
    /**
     * 组价前校验清单
     */
    async mergeCheck() {
      if (this.scrollDisabled) return false;
      this.scrollDisabled = true;
      this.checkLoading = true;
      const res: any = await mergeInitCheck(this.sequenceNbr, this.checkPage);
      console.log('res', res);
      this.checkLoading = false;
      if (!res) {
        this.readyPrice = false;
        this.loading = false;
        return false;
      }
      // status 0 正常 1 错误信息 2 状态中心互斥
      if (res.result.status === 2) {
        this.$message.error(res.result.message);
        this.readyPrice = false;
        this.loading = false;
        return false;
      }
      if (!res.status || res.status !== 200 || res.result.status === 1) {
        this.checkErrorVisible = true;
        this.readyPrice = false;
        this.loading = false;
        this.mergeInitErrors = [...this.mergeInitErrors, ...res.result.errors];
        if (this.checkPage >= res.result.errorPages) {
          this.scrollDisabled = true;
        } else {
          this.checkPage += 1;
          this.scrollDisabled = false;
        }
        return false;
      }
      return true;
    },
    resetCheckError() {
      this.checkPage = 1;
      this.mergeInitErrors = [];
      this.scrollDisabled = false;
    },
    continueCombined() {
      if (this.readyPrice) return;
      this.readyPrice = true;
      this.showtoast = false;
      this.list = [];
      this.loading = true;
      this.combinedPriceUsedTime = 0;
      this.constructMerge();
    },
    // 组价接口
    async functionMerge() {
      if (this.readyPrice) return;
      this.readyPrice = true;
      this.showtoast = false;
      this.list = [];
      // 这里先不提，因为需要点击立马进行动画
      this.loading = true;
      this.combinedPriceUsedTime = 0;
      this.progress = 0;
      this.resetCheckError();
      const checkResult = await this.initCheckErrorLoad();
      if (!checkResult) return;
      !this.isStopCombined && this.constructMerge();
    },
    constructMerge() {
      api
        .coccMtdsResource_1_cocc_builder_constructMerge(this.sequenceNbr)
        .then((res) => {
          if (res.status === 200) {
            this.currentTime = Date.now();
            this.start = true;
            // this.startWebSocket();
            this.$store.commit('SET_COMBINEDPRICESTATUS', true);
            if (this.start) {
              this.setIntervalInfo();
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.readyPrice = false;
        });
    },
    startInterval() {
      this.showtoast = false;
      this.list = [];
      this.combinedPriceUsedTime = 0;
      this.currentTime = Date.now();
      this.start = true;
      this.$store.commit('SET_COMBINEDPRICESTATUS', true);
      this.$store.commit('SET_ISRETRACTUNFOLD', true);
      if (this.start) {
        this.setIntervalInfo();
      }
    },
    setIntervalInfo() {
      if (Date.now() - this.currentTime > 3000) {
        this.currentTime = Date.now();
        if (this.isPriceAdjustmentOrValuation) {
          this.againMergeStatusByConstructId();
        } else {
          this.getunitProject();
        }
      }
      if (this.start) {
        this.timer = requestAnimationFrame(this.setIntervalInfo);
      }
    },
    startWebSocket() {
      this.loading = true;
      this.$store.commit('SET_COMBINEDPRICESTATUS', true);
      Vue.prototype.$websocket.dispatch(
        'WEBSOCKET_INIT',
        `/cocc-mtds-server/websocket/${this.sequenceNbr}`
      );
    },
    // 开始组价
    starprice(type = 1) {
      if (this.projectBidSequenceNbr && type) {
        // 1。点击替换，没有进行计价
        // 2. 已经有投标文件，组过价
        this.confirvisible = true;
        return;
      }
      this.confirvisible = false;
      if (this.isPriceAdjustmentOrValuation)
        return this.$message.error('项目工程量清单重新计价中');
      if (this.isPriceAdjustment)
        return this.$message.error('项目工程量清单存在人工调价未完成项');
      if (this.$store.getters.isProjectlack)
        return this.$message.error('请完善招标项目！');
      if (this.isBlankProject) return this.$message.error('请导入招标项目！');
      if (!this.isSetup) return this.$message.error('请配置组价策略！');
      if (this.listGenerating)
        return this.$message.warning('项目工程量投标清单正在生成中');
      this.successlist = [];
      this.peandinglist = [
        {
          id: '1',
          name: '组价初始化中',
        },
      ];
      this.functionMerge();
    },
    success() {
      this.getProjectDetail();
    },
    change(type = 1) {
      if (type) {
        this.width = this.width ? 0 : 360;
      } else {
        this.width = 360;
        this.setScrollbarHeight();
      }
    },
    mergeBySequenceNbr() {
      api
        .coccMtdsResource_1_construct_mergeBySequenceNbr(
          this.projectBidSequenceNbr
        )
        .then((res) => {
          if (res) {
            this.$message.success('测试V2专用接口请求成功~');
          }
        });
    },
    mergeV1BySequenceNbr() {
      api
        .coccMtdsResource_1_construct_mergeV1BySequenceNbr(this.sequenceNbr)
        .then((res) => {
          if (res) {
            this.$message.success('测试V1专用接口请求成功~');
          }
        });
    },

    /**
     * 组价策略 点击事件
     */
    combinedPriceSetting() {
      if (
        this.isPriceAdjustmentOrValuation ||
        this.combinedPriceStatus ||
        this.isPriceAdjustment
      ) {
        this.$message.warning('组价期间不可编辑组价策略');
      } else {
        this.groupshow = true;
      }
    },
    formatDuring(s: number) {
      const hours = Math.floor(s / (1000 * 60 * 60));
      const minutes = Math.floor((s % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((s % (1000 * 60)) / 1000);
      return `<span style="color:#FFAA09;">${hours}</span> 小时 <span style="color:#FFAA09;">${minutes}</span> 分钟 <span style="color:#FFAA09;">${seconds}</span> 秒`;
    },
    // 停止一键组价
    stopToSequenceNbr() {
      this.isStopCombined = true;
      coccSpsServer_1_coccBuilder_stopToSequenceNbr(this.sequenceNbr).then(
        (res) => {
          if (res.status === 200) {
            this.$message.success('组价已终止');
            this.endPrice(5);
            this.stopVisible = false;
          }
        }
      );
    },
  },
  destroyed() {
    // this.endPrice(0);
    if (this.isPriceAdjustmentOrValuation) {
      this.SET_PRICE_ADJUSTMENT_VALUATION(false);
    }
    this.start = false;
    this.timer && cancelAnimationFrame(this.timer);
    this.clearCombinedPriceTimer();
  },
});
