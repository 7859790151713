











































import Vue from 'vue';
import tabs from '../tabs.vue';
import { mapGetters } from 'vuex';
import { accAdd } from '@/utils';
import { ProjectTree, SingleSummaryType } from '@/interface/product';
import { coccMtdsResource_1_project_unitsBySequenceNbrSummary } from '@/api/projectDetail';
import { TableCalcStatus } from '@/interface';
import tableAlignCalc from '@/directives/tableAlignCalc';
const columns = [
  {
    key: 'dispNo',
    dataIndex: 'dispNo',
    align: 'center',
    width: 70,
    slots: { title: 'customTitle' },
    customCell: () => {
      return { style: { background: '#F5F7F9' } };
    },
  },
  {
    key: 'fbName',
    title: '名称',
    dataIndex: 'fbName',
    align: 'center',
    scopedSlots: { customRender: 'name' },
  },
  {
    key: 'formulaLabel',
    title: '计算基数',
    dataIndex: 'formulaLabel',
    align: 'center',
  },
  {
    key: 'fl',
    title: '费率(%)',
    dataIndex: 'fl',
    align: 'center',
  },
  {
    key: 'total',
    title: '金额(元)',
    dataIndex: 'total',
    align: 'center',
  },
  {
    key: 'rfee',
    title: '人工费(元)',
    dataIndex: 'rfee',
    align: 'center',
  },
  {
    key: 'cfee',
    title: '材料费(元)',
    dataIndex: 'cfee',
    align: 'center',
  },
  {
    key: 'jfee',
    title: '机械费(元)',
    dataIndex: 'jfee',
    align: 'center',
  },
];
interface Tabs extends ProjectTree {
  label: string;
}

export default Vue.extend({
  name: 'singleProject',
  data() {
    const tabs: Tabs[] = [];
    const projectData: SingleSummaryType[] = [];
    return {
      tabs,
      activeKey: '',
      tableHeight: 0,
      columns,
      tableScroll: {
        y: 0,
        x: 'max-content',
      },
      projectData,
      isUpdateHeight: false,
      updateTableCalc: '', // 用作更新表格计算
    };
  },
  created() {
    this.setTabs();
    window.addEventListener('resize', this.setTableHeight);
    this.setTableHeight();
  },
  computed: {
    ...mapGetters(['currentTreeInfo', 'isRetractUnfold']),
  },
  watch: {
    activeKey: function (val, oldVal) {
      if (val === oldVal) return '';
      this.getUnitSummary();
    },
    isUpdateHeight: function (val) {
      if (val) {
        this.setTableHeight();
      }
    },
    currentTreeInfo: {
      handler: function (val) {
        if (val.type !== '02') return '';
        this.setTabs();
      },
      deep: true,
    },
    isRetractUnfold: function (val, oldVal) {
      if (val !== oldVal) {
        this.updateTableCalc = val
          ? TableCalcStatus.REDUCE
          : TableCalcStatus.ENLARGE;
      }
    },
  },
  methods: {
    getUnitSummary() {
      const sequenceNbrArr = this.activeKey.split('_');
      const sequenceNbr = sequenceNbrArr[0] || sequenceNbrArr[1];
      if (!sequenceNbr || sequenceNbr === 'null') return '';
      coccMtdsResource_1_project_unitsBySequenceNbrSummary(sequenceNbr).then(
        (res) => {
          const data = res.result;
          const fieldList = [
            {
              dispNo: '1',
              fbName: data.upName + ' 合计',
              code: 'hj',
              isMultiple: true,
            },
            {
              dispNo: '1.1',
              fbName: '分部分项工程量清单计价 合计',
              formulaLabel: '分部分项合计',
              code: 'fbfx',
              isMultiple: true,
            },
            {
              dispNo: '1.2',
              fbName: '措施项目清单计价 合计',
              formulaLabel: '措施项目清单计价合计',
              code: 'csx',
              isMultiple: true,
            },
            {
              dispNo: '1.2.1',
              fbName: '单价措施项目工程量清单计价 合计',
              formulaLabel: '单价措施项目',
              code: 'djcsx',
              isMultiple: true,
            },
            {
              dispNo: '1.2.2',
              fbName: '其他总价措施项目清单计价 合计',
              formulaLabel: '其他总价措施项目',
              code: 'zjcsx',
              isMultiple: true,
            },
            {
              dispNo: '1.3',
              fbName: '其他项目清单计价 合计',
              code: 'qtxmhj',
              formulaLabel: '其他项目合计',
            },
            {
              dispNo: '1.4',
              fbName: '规费 合计',
              code: 'gfee',
              formulaLabel: '规费合计',
            },
            {
              dispNo: '1.5',
              fbName: '安全生产、文明施工费 合计',
              code: 'safeFee',
              formulaLabel: '安全生产、文明施工费',
            },
            {
              dispNo: '1.6',
              fbName: '税前工程造价 合计',
              code: 'sqgczj',
              description: '0',
              formulaLabel:
                '分部分项工程量清单计价合计+措施项目清单计价合计+其他项目清单计价合计+规费+安全生产、文明施工费',
            },
            {
              dispNo: '1.6.1',
              fbName: '进项税额 合计',
              code: 'jxse',
              description: '0',
              formulaLabel: '见增值税进项税额计算汇总表',
            },
            {
              dispNo: '1.7',
              fbName: '销项税额 合计',
              code: 'xxse',
              description: '0',
              formulaLabel: '税前工程造价-其中：进项税额',
            },
            {
              dispNo: '1.8',
              fbName: '增值税应纳税额 合计',
              code: 'zzsynse',
              description: '0',
              formulaLabel: '销项税额-其中：进项税额',
            },
            {
              dispNo: '1.9',
              fbName: '附加税费 合计',
              code: 'fjse',
              description: '0',
              formulaLabel: '增值税应纳税额',
            },
            {
              dispNo: '1.10',
              fbName: '税金',
              code: 'sj',
              formulaLabel: '增值税应纳税额+附加税费',
            },
            {
              dispNo: '1.11',
              fbName: '工程造价 合计',
              code: 'uptotal',
              formulaLabel: '税前工程造价+税金',
            },
          ];
          let totalRfee = 0;
          let totalCfee = 0;
          let totalJfee = 0;
          this.projectData = fieldList.filter((item: SingleSummaryType) => {
            const isMultiple = item.isMultiple;
            const totalKey = (
              isMultiple ? `${item.code}hj` : item.code
            ) as keyof typeof data;
            item.total = data[totalKey] as string;
            const rgfKey = `${item.code}rgf` as keyof typeof data;
            const clfKey = `${item.code}clf` as keyof typeof data;
            const jxfKey = `${item.code}jxf` as keyof typeof data;
            item.rfee = (isMultiple ? data[rgfKey] : '/') as string;
            item.cfee = (isMultiple ? data[clfKey] : '/') as string;
            item.jfee = (isMultiple ? data[jxfKey] : '/') as string;
            if (item.dispNo === '1.10') {
              if (data.description === '1') {
                item.dispNo = '1.6';
                item.formulaLabel = '税前造价';
                item.code = 'xxse';
              }
            }
            if (item.dispNo === '1.11') {
              if (data.description === '1') {
                item.dispNo = '1.7';
              }
            }
            const fl = {
              fjse: 'fjsffl',
              xxse: 'xxsefl',
            };
            if (!['hj'].includes(item.code)) {
              item.fl = fl[item.code] ? data[fl[item.code]] : '/';
            }
            if (['1.1', '1.2'].includes(item.dispNo)) {
              totalRfee = Number(accAdd(Number(item.rfee), totalRfee));
              totalCfee = Number(accAdd(Number(item.cfee), totalCfee));
              totalJfee = Number(accAdd(Number(item.jfee), totalJfee));
            }
            if (item.code === 'uptotal') {
              item.rfee = totalRfee.toFixed(2);
              item.cfee = totalCfee.toFixed(2);
              item.jfee = totalJfee.toFixed(2);
            }
            if (data.description === '1' && item.description === '0') {
              return;
            }
            return item;
          });
          this.isUpdateHeight = true;
          this.updateTableCalc = TableCalcStatus.UPDATE;
        }
      );
    },
    /**
     * 设置tab数据
     */
    setTabs() {
      const treeInfo = JSON.parse(
        JSON.stringify(this.currentTreeInfo.children)
      );
      this.tabs = treeInfo.map((item: Tabs) => {
        const bidSequenceNbr = item.bidSequenceNbr || '';
        item.label = item.name;

        item.name = `${bidSequenceNbr}_${item.sequenceNbr}`;
        return item;
      });
      this.activeKey = this.tabs[0]?.name;
    },

    /**
     * 设置tab高度
     */
    setTableHeight() {
      this.$nextTick(() => {
        const projectEl = this.$refs.singleProject as HTMLDivElement;
        const totalHeight = projectEl.clientHeight;
        const tabCom = this.$refs.tabs as Vue;
        const tabEl = tabCom.$el as HTMLDivElement;
        const tabHeight = tabEl.clientHeight;
        const tableHeadHeight: number = (
          document.querySelector('.ant-table-thead') as HTMLElement
        ).clientHeight;
        this.tableScroll = {
          y: totalHeight - tabHeight - tableHeadHeight - 3,
          x: 'max-content',
        };
        this.isUpdateHeight = false;
      });
    },
    updateTab(tab: string) {
      this.activeKey = tab;
    },
    rowClassName(record: SingleSummaryType) {
      if (record.dispNo && record.dispNo.split('.').length <= 1) {
        return 'project-detail-table-highlight';
      }
      return '';
    },
  },
  directives: {
    tableAlignCalc,
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setTableHeight);
  },
  components: { tabs },
});
