
































import Vue from 'vue';

export default Vue.extend({
  name: 'unorderedInfo',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false);
    },
  },
});
