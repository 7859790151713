





























































































































import Vue from 'vue';
import * as api from '@/api/Import';
import {
  userOperationFirst,
  userOperationConfirm,
  coccMtdsResource_1_cocc_builder_constructMergeRepeat,
  getAgencyCode,
} from '@/api/projectDetail';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { checkFileType, subtract } from '@/utils/index';
import AliOss from '@/utils/alioss';
import ImportForm from '@/views/projects/popup/import-form.vue';
import Cookies from 'js-cookie';
const clickoutside = {
  // 初始化指令
  bind(el: any, binding: any) {
    function documentHandler(e: any) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler;
    document.addEventListener('click', documentHandler);
  },
  unbind(el: any) {
    // 解除事件监听
    document.removeEventListener('click', el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
};

const DEFAULT_IMPORT_NUM = 3; // 默认导入次数
export default Vue.extend({
  name: 'detailHeader',
  props: {
    constructName: {
      type: String,
    },
  },
  data() {
    return {
      confirmationProject: false, //上传二次确认
      fileList: [],
      show: false,
      filepath: '',
      showStatus: false, // 打开导入弹框
      visible: false,
      guideVisible: false,
      artificialVisible: false, // 人工调价弹框是否展示
      file: null,
      paramsFileData: '',
      exportVisible: false, // 导出弹框是否展示
      agencyCode: null,
    };
  },
  computed: {
    ...mapGetters([
      'combinedPriceStatus',
      'projectBidSequenceNbr',
      'projectName',
      'isPriceAdjustmentOrValuation',
      'isPriceAdjustment',
      'isThereReplace',
      'listGenerating',
      'listGenerateSuccess',
      'projectSpaceDetail',
      'isExportGenerateList',
      'readOnly',
      'userInfo',
      'combinedSuccessVisible',
      'portalState',
    ]),
    isDownload(): boolean {
      return !(
        this.combinedPriceStatus ||
        !this.projectBidSequenceNbr ||
        this.isPriceAdjustmentOrValuation ||
        this.isPriceAdjustment ||
        !this.listGenerateSuccess
      );
    },
    /**
     * 剩余导入次数
     */
    importChangeSurplusNum(): number {
      const projectSpaceDetail = this.projectSpaceDetail;
      if (!projectSpaceDetail) return 0;
      const importNumber = projectSpaceDetail.importNumber;
      const num = Number(
        subtract(DEFAULT_IMPORT_NUM, importNumber < 0 ? 0 : importNumber)
      );
      return num <= 0 ? 0 : num;
    },
  },
  directives: { clickoutside },
  mounted() {
    if (!this.readOnly) {
      this.guideOpen(1);
      this.getAgencyCode();
    }
  },
  methods: {
    ...mapMutations(['SET_PRICE_ADJUSTMENT_VALUATION']),
    ...mapActions(['getProjectSpace']),

    zbt() {
      this.$message.warning('功能正在建设中...');
    },
    uploadProject() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      if (!this.isNextOperation()) {
        return;
      }
      if (!this.$store.getters.isBlankProject) {
        this.confirmationProject = true;
        this.getProjectSpace();
      } else {
        this.showStatus = true;
        // (
        //   document.querySelector(
        //     '.uploadproject .ant-upload input'
        //   ) as HTMLElement
        // ).click();
      }
    },
    exportProject() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      if (this.isExportGenerateList) {
        this.$store.commit('SET_IS_EXPORT_MINIMALITY', false);
        return;
      }
      if (!this.isNextOperation()) {
        return;
      }
      if (!this.projectBidSequenceNbr) {
        this.$message.warning('您还没有完成组价，请先进行一键组价');
        return;
      }
      this.exportVisible = true;
    },
    handleupload() {
      // (
      //   document.querySelector(
      //     '.uploadproject .ant-upload input'
      //   ) as HTMLElement
      // ).click();
      this.confirmationProject = false;
      this.showStatus = true;
    },
    /**
     * @description: 打开项目切换弹框
     * @param {*}
     * @return {*}
     */
    projectSwitcher() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      this.show = true;
    },
    /**
     * @description: 关闭项目切换弹框
     * @param {*}
     * @return {*}
     */
    handleClose() {
      this.show = false;
    },
    /**
     * @description: 打开操作指引页面
     * @param {*}
     * @return {*}
     */
    guideOpen(type: number) {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      const ggId = sessionStorage.getItem('ggId');
      const isVisitor = sessionStorage.getItem('isVisitor');
      const cookieGId = Cookies.get('ggId');
      console.log('======', ggId, isVisitor, cookieGId);
      if (ggId || (isVisitor && cookieGId)) {
        sessionStorage.removeItem('ggId');
        sessionStorage.removeItem('isVisitor');
        return;
      }
      if (type === 0) {
        this.guideVisible = true;
      } else {
        userOperationFirst().then((res) => {
          this.guideVisible = !res.result;
          userOperationConfirm();
        });
      }
    },
    goUserHandbook() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      window.open(
        'https://ysf.oss.yunsuanfang.com/publics/operation_manual/%E4%BA%91%E7%AE%97%E6%88%BF%E5%B9%B3%E5%8F%B0%E7%94%A8%E6%88%B7%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf',
        '_black'
      );
    },
    goArtificialSummary() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      this.$store.commit('SET_ARTIFICIAL_SUMMARY', true);
    },
    analysis() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      if (this.combinedPriceStatus) {
        this.$message.warning('项目正在组价中');
        return false;
      }
      if (this.isPriceAdjustmentOrValuation) {
        this.$message.warning('项目工程量清单重新计价中');
        return false;
      }
      if (this.isPriceAdjustment) {
        this.$message.warning('项目工程量清单存在人工调价未完成项');
        return false;
      }
      if (!this.projectBidSequenceNbr) {
        this.$message.warning('您还没有完成组价，请先进行一键组价');
        return false;
      }
      this.$store.commit('SET_COMBINED_SUCCESS_VISIBLE', true);
    },
    // 点击人工调价按钮打开弹框
    artificialAdjustment() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      if (!this.isNextPriceOperation()) return;
      if (this.isThereReplace) {
        this.$message.warning('项目清单有变更请先进行重新计价');
        return;
      }
      this.artificialVisible = true;
    },
    isNextOperation() {
      if (this.combinedPriceStatus) {
        this.$message.warning('项目正在组价中');
        return false;
      }
      if (this.isPriceAdjustmentOrValuation) {
        this.$message.warning('项目工程量清单重新计价中');
        return false;
      }
      if (this.isPriceAdjustment) {
        this.$message.warning('项目工程量清单存在人工调价未完成项');
        return false;
      }
      if (this.listGenerating) {
        this.$message.warning('项目工程量投标清单正在生成中');
        return false;
      }
      return true;
    },
    isNextPriceOperation() {
      if (!this.isNextOperation()) return false;
      if (!this.projectBidSequenceNbr) {
        this.$message.warning('您还没有完成组价，请先进行一键组价');
        return false;
      }
      return true;
    },
    /**
     * 是否重新计价
     */
    isAgainMerge() {
      if (
        this.combinedPriceStatus ||
        this.isPriceAdjustmentOrValuation ||
        this.isPriceAdjustment ||
        !this.projectBidSequenceNbr ||
        !this.isThereReplace ||
        this.listGenerating
      ) {
        return false;
      }
      return true;
    },
    /**
     * 重新计价
     */
    againMerge() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      this.isNextPriceOperation();
      if (!this.isAgainMerge()) return '';
      const bidSequenceNbr = this.projectBidSequenceNbr;
      coccMtdsResource_1_cocc_builder_constructMergeRepeat(bidSequenceNbr)
        .then((res) => {
          if (res.status === 200) {
            this.SET_PRICE_ADJUSTMENT_VALUATION(true);
            // this.startWebSocket();
            // this.priceAdjustmentOrValuationStatus();
          } else {
            this.SET_PRICE_ADJUSTMENT_VALUATION(false);
          }
        })
        .catch(() => {
          this.SET_PRICE_ADJUSTMENT_VALUATION(false);
        });
    },
    goProjectList() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      this.$router.push({
        name: 'ysf/project',
      });
    },
    getAgencyCode() {
      getAgencyCode().then((res: any) => {
        if (res.status === 200) {
          this.agencyCode = res.result;
        }
      });
    },
  },
  components: {
    ImportForm,
    combinedPriceAnalysis: () => import('./combinedPriceAnalysis.vue'),
    list: () => import('./../proList/list.vue'),
    guide: () => import('./../proList/guide.vue'),
    artificialAdjustment: () =>
      import('@/views/projects/detail/artificialAdjustment/index.vue'),
    exportTender: () => import('./exportTender/index.vue'),
  },
});
