













































import Vue from 'vue';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { ProjectTree, RefreshTreeFrom } from '@/interface/product';
import { coccMtdsResource_1_project_constructsBySequenceNbrTree } from '@/api/projectDetail';
import EditLeftSideName from '@/views/projects/detail/editLeftSideName.vue';

export default Vue.extend({
  name: 'detailLeftSide',
  components: { EditLeftSideName },
  props: {
    totalPrice: {
      type: Number,
    },
    treeList: {
      type: Array,
    },
  },
  data() {
    const projectTree: ProjectTree[] = [];
    const selectedKeys: string[] = [];
    const expandedKeys: string[] = [];
    return {
      projectTree,
      selectedKeys,
      expandedKeys,
      editVisible: false,
    };
  },
  watch: {
    refreshTreeFrom: function (val, oldVal) {
      if (!val || val === oldVal) return '';
      this.getTreeData();
    },
    artificialSummary: function (val) {
      if (val) this.getTreeData();
    },
    combinedPriceStatus: function (val) {
      if (val) {
        this.$store.commit('SET_ISUNFOLD', true);
        this.$store.commit('SET_ISTENDERUNFOLD', true);
        this.$store.commit('SET_ISRETRACTUNFOLD', true);
      }
    },
  },
  computed: {
    ...mapGetters([
      'refreshTreeFrom',
      'currentTreeInfo',
      'projectSequenceNbr',
      'tenderTotal',
      'readOnly',
      'combinedPriceStatus',
      'projectBidSequenceNbr',
      'isPriceAdjustmentOrValuation',
      'isPriceAdjustment',
      'listGenerating',
      'userInfo',
      'artificialSummary',
    ]),
  },
  created() {
    this.getTreeData();
  },
  methods: {
    ...mapMutations([
      'SET_CURRENT_TREE_INFO',
      'SET_RefreshTree',
      'SET_PROJECT_BIDSEQUENCENBR',
      'SET_PROJECT_ISLACK',
    ]),
    ...mapActions(['getGenerateListStatus', 'getProjectDetail']),
    getTreeData() {
      const sequenceNbr =
        (this.$route.query.sequenceNbr as string) || this.projectSequenceNbr;
      if (!sequenceNbr) return '';
      coccMtdsResource_1_project_constructsBySequenceNbrTree(sequenceNbr)
        .then((res) => {
          if (res.status === 200) {
            this.projectTree = res.result;
            this.getIfChildrenMissing(res.result);
            const isUpdateCurrentTree =
              this.refreshTreeFrom === RefreshTreeFrom.COMBINED_PRICE ||
              this.refreshTreeFrom === '';
            let rootTreeInfo;
            const receiveResult = JSON.parse(
              sessionStorage.getItem('receiveResult') as string
            );
            if (res.result.length > 0) {
              if (receiveResult && receiveResult.tbSpSequenceNbr) {
                res.result[0].children.forEach((item) => {
                  if (item.bidSequenceNbr === receiveResult.tbSpSequenceNbr) {
                    item.children.forEach((children) => {
                      if (
                        children.bidSequenceNbr ===
                        receiveResult.tbUpSequenceNbr
                      ) {
                        rootTreeInfo = children;
                        const rootKey = rootTreeInfo.sequenceNbr;
                        this.selectedKeys = [rootKey];
                        this.expandedKeys = [
                          res.result[0].sequenceNbr,
                          item.sequenceNbr,
                          rootKey,
                        ];
                      }
                    });
                  }
                });
                this.SET_PROJECT_BIDSEQUENCENBR(res.result[0]?.bidSequenceNbr);
              } else {
                rootTreeInfo = res.result[0];
                if (isUpdateCurrentTree) {
                  const rootKey = rootTreeInfo.sequenceNbr;
                  this.selectedKeys = [rootKey];
                  this.expandedKeys = [rootKey];
                }
                if (
                  rootTreeInfo.type === '01' &&
                  !rootTreeInfo.children.length
                ) {
                  this.blankDataHanlder();
                }
                this.SET_PROJECT_BIDSEQUENCENBR(rootTreeInfo?.bidSequenceNbr);
              }
            }
            if (isUpdateCurrentTree) {
              this.SET_CURRENT_TREE_INFO(rootTreeInfo);
            }
            if (!rootTreeInfo.bidSequenceNbr) {
              this.$store.commit('SET_ISUNFOLD', true);
              this.$store.commit('SET_ISTENDERUNFOLD', true);
              this.$store.commit('SET_ISRETRACTUNFOLD', true);
            } else {
              this.$store.commit('SET_ISUNFOLD', true);
              this.$store.commit('SET_ISTENDERUNFOLD', false);
              this.$store.commit('SET_ISRETRACTUNFOLD', false);
            }
          }
        })
        .finally(() => {
          this.SET_RefreshTree('');
        });
    },
    /**
     * @description: 判断项目是否完整
     * @param {*}
     * @return {*}
     */
    getIfChildrenMissing(list: ProjectTree[]) {
      let ifChildrenMissing = false;
      const forTree = (list) => {
        for (let i of list) {
          if (i.ifChildrenMissing) {
            ifChildrenMissing = true;
            this.SET_PROJECT_ISLACK(true);
          }
          if (ifChildrenMissing) {
            return;
          }
          if (i.children.length) {
            forTree(i.children);
          }
        }
      };
      forTree(list);
      if (!ifChildrenMissing) this.SET_PROJECT_ISLACK(false);
    },
    /**
     * 空项目数据处理
     */
    blankDataHanlder() {
      const blankData: ProjectTree[] = [
        {
          bidSequenceNbr: '',
          children: [
            {
              bidSequenceNbr: '',
              children: [],
              name: '单位工程',
              parentId: '',
              sequenceNbr: null,
              type: '03',
              xmType: null,
              isBlank: true,
            },
          ],
          name: '未命名',
          parentId: '',
          sequenceNbr: '',
          type: '02',
          xmType: null,
          isBlank: true,
        },
      ];
      this.projectTree[0].isBlank = true;
      this.projectTree[0].children = blankData;
    },
    selectNode(
      selectedKeys: string[],
      { node }: { node: { dataRef: Record<string, string>; expanded: boolean } }
    ) {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      this.SET_CURRENT_TREE_INFO(node.dataRef);
      if (!node.expanded) {
        // this.expandedKeys.push(node.dataRef.sequenceNbr);
      } else {
        if (node.dataRef.type === '01') {
          this.selectedKeys = [node.dataRef.sequenceNbr];
          return;
        }
        // this.expandedKeys = this.expandedKeys.filter((key: string) => {
        //   return key !== node.dataRef.sequenceNbr;
        // });
      }
    },
    openEdit() {
      if (this.projectTree[0].isBlank) {
        this.$message.warning('请先导入工程项目');
        return;
      }
      if (this.combinedPriceStatus) {
        this.$message.warning('项目正在组价中');
        return;
      }
      if (this.isPriceAdjustmentOrValuation) {
        this.$message.warning('项目工程量清单重新计价中');
        return;
      }
      if (this.isPriceAdjustment) {
        this.$message.warning('项目工程量清单存在人工调价未完成项');
        return;
      }
      if (this.listGenerating) {
        this.$message.warning('项目工程量投标清单正在生成中');
        return;
      }
      this.editVisible = true;
    },
    updateList() {
      this.editVisible = false;
      this.getTreeData();
      if (this.projectBidSequenceNbr) {
        this.getGenerateListStatus();
      }
      this.getProjectDetail();
    },
  },
});
