

























































































































































































































import Vue from 'vue';
import { setTableHeaderWidth } from '@/utils';
import { quotaInfo } from '@/api/project';
import { mapGetters } from 'vuex';
import { resetQdColor } from '@/api/projectDetail';
const dialogColumns = [
  {
    title: '名称',
    dataIndex: 'rcjName',
    key: 'rcjName',
    scopedSlots: { customRender: 'rcjName' },
    align: 'center',
    width: 200,
    customCell: () => {
      return { style: { position: 'relative', 'padding-right': '50px' } };
    },
  },
  {
    title: '类型',
    dataIndex: 'kind',
    key: 'kind',
    scopedSlots: { customRender: 'kind' },
    align: 'center',
  },
  {
    title: '规格型号',
    dataIndex: 'specification',
    key: 'specification',
    scopedSlots: { customRender: 'specification' },
    align: 'center',
  },
  {
    title: '计量单位',
    dataIndex: 'unit',
    key: 'unit',
    scopedSlots: { customRender: 'unit' },
    align: 'center',
  },
  {
    title: '消耗量',
    dataIndex: 'resQty',
    key: 'resQty',
    scopedSlots: { customRender: 'resQty' },
    align: 'center',
  },
  {
    title: '合计数量',
    dataIndex: 'calcQty',
    key: 'calcQty',
    scopedSlots: { customRender: 'calcQty' },
    align: 'center',
  },
  {
    title: '定额价(元)',
    dataIndex: 'dePrice',
    key: 'dePrice',
    scopedSlots: { customRender: 'dePrice' },
    align: 'center',
  },
  {
    title: '市场价(元)',
    dataIndex: 'standardPrice',
    key: 'standardPrice',
    align: 'center',
  },
  {
    title: '除税系数(%)',
    dataIndex: 'taxRemoval',
    key: 'taxRemoval',
    scopedSlots: { customRender: 'taxRemoval' },
    align: 'center',
  },
  {
    title: '除税市场价(元)',
    dataIndex: 'initialPrice',
    key: 'initialPrice',
    scopedSlots: { customRender: 'initialPrice' },
    align: 'center',
  },
];

const priceColumns = [
  {
    title: '序号',
    dataIndex: 'sequenceNbr',
    key: 'sequenceNbr',
    scopedSlots: { customRender: 'sequenceNbr' },
    align: 'center',
    width: 200,
  },
  {
    title: '名称',
    dataIndex: 'bdName',
    key: 'bdName',
    scopedSlots: { customRender: 'bdName' },
    align: 'center',
    width: 200,
    customCell: () => {
      return { style: { position: 'relative' } };
    },
  },
  {
    title: '计算基数',
    dataIndex: 'computationalBase',
    key: 'computationalBase',
    scopedSlots: { customRender: 'computationalBase' },
    align: 'center',
  },
  {
    title: '费率(%)',
    dataIndex: 'rate',
    key: 'rate',
    scopedSlots: { customRender: 'rate' },
    align: 'center',
  },
  {
    title: '金额',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' },
    align: 'center',
    customCell: () => {
      return { style: { color: '#FF5757' } };
    },
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    scopedSlots: { customRender: 'remark' },
    align: 'center',
  },
];

export default Vue.extend({
  name: 'index',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentQuotaInfo: {
      type: Object,
      default: () => null,
    },
    currentInventoryInfo: {
      type: Object,
    },
    type: {
      type: Number,
      default: 1, // 1 分部分项 2 单价措施
    },
  },
  data() {
    return {
      dialogColumns,
      priceColumns,
      active: 1,
      dialogData: {
        udcRenCaiJiModels: [],
      } as any,
      priceData: [] as any[],
      loading: false,
      componentHeight: 0,
      tableHeadHeight: 0,
    };
  },
  watch: {
    visible: function (val) {
      if (val) {
        this.quotaInfo();
        this.setComponentHeight();
        window.addEventListener('resize', this.getTableHeaderWidth);
      } else {
        window.removeEventListener('resize', this.getTableHeaderWidth, false);
      }
    },
  },
  computed: {
    ...mapGetters([
      'currentTreeInfo',
      'isQuotaLibraryIdentify',
      'projectBidSequenceNbr',
      'listGenerating',
    ]),
    componentsStyle() {
      let height = 0;
      if (
        this.dialogData?.udcRenCaiJiModels.length > 0 ||
        this.priceData.length > 0
      ) {
        height = this.componentHeight - this.tableHeadHeight;
        return { y: height };
      } else {
        height = this.componentHeight; // 空数据的样式高度 = 50vh - 表头高度 - margin(上下28) - padding(上下16)
        return {
          height: height + 'px',
        };
      }
    },
  },
  methods: {
    getContainer() {
      return document.getElementById('ysf_body');
    },
    hide() {
      this.priceData = [];
      this.dialogData.udcRenCaiJiModels = [];
      this.active = 1;
      this.$emit('update:visible', false);
      if (this.currentInventoryInfo.highlightColor === 1) {
        this.$emit('updateColorStatus', this.currentInventoryInfo);
      }
    },
    updatePrice() {
      this.$store.commit('SET_IS_START_COMBINED', true);
      console.log('确定接口');
      this.$emit('update:visible', false);
    },
    /**
     * type
     * 1 未组价 2 特殊清单项 3 组价推荐
     */
    goCostList() {
      if (this.listGenerating) {
        this.$message.warning('项目工程量投标清单正在生成中');
        return;
      }
      const query = this.$route.query;
      if (!query) return;
      Object.assign(query, {
        type: 3,
        projectBidSequenceNbr: this.projectBidSequenceNbr,
        qdSequenceNbr: this.currentInventoryInfo.sequenceNbr,
        qdType: this.type === 1 ? 'fbfx' : 'djcs',
        biddingType: this.currentInventoryInfo.biddingType, // 招投标类型 0招标 1投标
      });
      sessionStorage.setItem('ysf_currentProjectQuery', JSON.stringify(query));
      this.$router.push('/ysf/costQuotaLibrary/quotaAdjustment');
    },
    containerChange() {
      return document.getElementsByClassName('quota-detail')[0];
    },
    changeTab(value) {
      this.active = value;
      console.log('changeTab');
      if (value === 2) {
        this.getPriceData();
      }
    },
    quotaInfo() {
      this.loading = true;
      quotaInfo(
        this.currentTreeInfo.bidSequenceNbr,
        this.currentQuotaInfo.sequenceNbr,
        this.type === 1 ? 'fbfx' : 'measure'
      ).then((res) => {
        console.log('res', res);
        if (res.status === 200 && res.result) {
          this.loading = false;
          this.dialogData = res.result;
          if (this.currentInventoryInfo.highlightColor === 1) {
            this.resetQdColor();
          }
          if (this.dialogData?.udcRenCaiJiModels.length > 0) {
            this.getTableHeaderWidth();
          }
        }
      });
    },
    getPriceData() {
      this.priceData = [
        {
          sequenceNbr: 1,
          bdName: '直接费',
          computationalBase: '人工费+材料费+机械费',
          rate: '--',
          price: this.dialogData.directFee,
          remark: '1.1+1.2+1.3',
        },
        {
          sequenceNbr: 1.1,
          bdName: '人工费',
          computationalBase: '人工费',
          rate: '--',
          price: this.dialogData.rgfZongJia,
          remark: '',
        },
        {
          sequenceNbr: 1.2,
          bdName: '材料费',
          computationalBase: '材料费',
          rate: '--',
          price: this.dialogData.clfZongJia,
          remark: '',
        },
        {
          sequenceNbr: 1.3,
          bdName: '机械费',
          computationalBase: '机械费',
          rate: '--',
          price: this.dialogData.jxfZongJia,
          remark: '',
        },
        {
          sequenceNbr: 2,
          bdName: '管理费',
          computationalBase: '人工费+机械费',
          rate: Number(this.dialogData.glfRate * 100).toFixed(2),
          price: this.dialogData.glfZongJia,
          remark: '',
        },
        {
          sequenceNbr: 3,
          bdName: '利润',
          computationalBase: '人工费+机械费',
          rate: Number(this.dialogData.lrRate * 100).toFixed(2),
          price: this.dialogData.lrZongJia,
          remark: '',
        },
        {
          sequenceNbr: 4,
          bdName: '合价',
          computationalBase: '直接费+管理费+利润',
          rate: '--',
          price: this.dialogData.totalDe,
          remark: '1+2+3',
        },
      ];
      this.getTableHeaderWidth();
    },
    setComponentHeight() {
      this.$nextTick(() => {
        const tenderHeaderEl = this.$refs.headerInfo as HTMLDivElement;
        const footerEl = this.$refs.footerInfo as HTMLDivElement;
        const tenderHeaderHeight = tenderHeaderEl?.clientHeight;
        const footerHeight = footerEl?.clientHeight || 0;
        const changeTabEl = this.$refs.changeTab as HTMLDivElement;
        const changeTabHeight = changeTabEl?.clientHeight;
        const quotaInfoEl = this.$refs.quotaInfo as HTMLDivElement;
        const quotaInfoHeight = quotaInfoEl?.clientHeight;
        this.componentHeight =
          window.innerHeight -
          tenderHeaderHeight -
          changeTabHeight -
          quotaInfoHeight -
          30 -
          25 -
          16 -
          6 -
          footerHeight; // 页面总高度 - 标题头部高度 - tab切换高度 - 定额信息高度 - 30(content margin) - 25(tab margin-bottom) - 6(border) - 底部模块
        const tableEl = document.querySelector(
          '.ant-table-thead'
        ) as HTMLElement;
        let tableHeadHeight = 0;
        if (tableEl) {
          tableHeadHeight = tableEl.clientHeight;
        }
        this.tableHeadHeight = tableHeadHeight;
      });
    },
    getTableHeaderWidth() {
      setTableHeaderWidth('.quota-detail-list');
    },
    /**
     * 重置(恢复)高亮颜色-清单
     */
    resetQdColor() {
      const isSameItem =
        this.currentInventoryInfo.extend2 !== '3' &&
        Number(this.currentInventoryInfo.extend3) > 0;
      let apiData = {
        queryTable: this.type === 1 ? 'fbfx' : 'djcs',
        ifSameItem: isSameItem,
        tbConstructSequenceNbr: isSameItem ? this.projectBidSequenceNbr : null,
        equalItemSpecial: isSameItem
          ? this.currentInventoryInfo.equalItemSpecial
          : null,
      };
      resetQdColor(this.currentInventoryInfo.sequenceNbr, apiData).then(
        (res) => {
          console.log('重置(恢复)高亮颜色', res);
        }
      );
    },
  },
});
